<template>
  <ContainerPage>
    <menu-tab @menuTab="menuTab" :tab="tab"></menu-tab>
    <div class="d-flex flex-column flex-lg-row w-100">
      <div class="w-100" v-if="tab == 'roles'">
        <roles-manager></roles-manager>
      </div>
      <div class="w-100" v-if="tab == 'users'">
        <users-manager></users-manager>
      </div>
      <div class="w-100" v-if="tab == 'track'">
        <keep-alive>
          <users-tracker></users-tracker>
        </keep-alive>
      </div>
      <div class="w-100" v-if="tab == 'cache'">
        <keep-alive>
          <cache-manager></cache-manager>
        </keep-alive>
      </div>
    </div>
  </ContainerPage>
</template>
<script>
import ContainerPage from "../components/containers/ContainerPage.vue";
import RolesManager from "@/components/admin/RolesManager.vue";
import UsersManager from "@/components/admin/UsersManager.vue";
import UsersTracker from "@/components/admin/UsersTracker.vue";
import CacheManager from "@/components/admin/CacheManager.vue";
import MenuTab from "@/components/admin/Menu.vue";

export default {
  name: "Admin",
  components: {
    ContainerPage,
    RolesManager,
    UsersManager,
    UsersTracker,
    CacheManager,
    MenuTab
  },
  data() {
    return {
      tab: "roles"
    };
  },
  mounted() {},
  methods: {
    menuTab(menuTab) {
      this.tab = menuTab;
    }
  }
};
</script>
<style></style>
