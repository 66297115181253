<template>
  <div class="d-flex flex-column flex-lg-row">
    <div v-background-3 class="p-3 w-100 rounded mb-3">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h5 class="m-0">Gestion du cache</h5>
      </div>
      <div class="">
        <p>
          Le cache dure 24h, il est automatiquement supprimé après ce délai.
          Vous pouvez le supprimer manuellement via ce bouton.
        </p>
        <button class="btn btn-danger" @click="deleteCache">
          Supprimer le cache
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      feedback: {},
      loading: false,
      themes: []
    };
  },
  mounted() {},
  methods: {
    deleteCache: function() {
      if (confirm("Etes vous certain de vouloir supprimer le cache ?")) {
        this.loading = true;
        this.$http
          .delete("/cache")
          .then(() => {})
          .finally(() => (this.loading = false));
      }
    }
  },
  computed: {}
};
</script>
<style></style>
